import React from 'react';
import { Helmet } from 'react-helmet';
import AppProvider from 'contexts/AppProvider';

const RootLayout = ({ children }) => {
  return (
    <AppProvider>
      <Helmet>
        <title>Telecomunicaciones Bantel C.A.</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </AppProvider>
  );
};

export default RootLayout;
