import React, { useState } from 'react';

import AppContext from './AppContext';

const Provider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  return (
    <AppContext.Provider
      value={{
        theme,
        toggleTheme: () => {
          if (theme === 'light') {
            setTheme('dark');
          } else {
            setTheme('light');
          }
        },
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default Provider;
